$(document).ready(function() {
  initHomeBanner();
  initSyllabusSlider();
  initInsta();
  initInnerBanner();
  initMobileMenu();

  // imgToBG($(".bg-img"), ".img-bg");

  moveModalToBody("#search-modal");
  moveModalToBody("#newsletter-modal");
  moveModalToBody("#result-modal");
  moveModalToBody("#tutor-modal");

  $(window).scroll(stickyHeader);

  $(".venobox").venobox();

  $("#back-to-top").on("click", function(ev) {
    ev.preventDefault();

    $("html, body").animate(
      {
        scrollTop: 0
      },
      "500"
    );
  });

  $("#search-modal").on("shown.bs.modal", function() {
    $("#keyword").trigger("focus");
  });

  initStepModal();
  initTutorListener();
  initNewsletterListener();

  AOS.init();
});

function initHomeBanner() {
  if ($("#banner").length) {
    var homeBanner = new Swiper("#banner", {
      autoplay: {
        delay: 5000
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets"
      }
    });
  }
}

function initInnerBanner() {
  if ($("#inner-banner").length) {
    var innerBanner = new Swiper("#inner-banner", {
      autoplay: {
        delay: 5000
      }
    });
  }
}

function initSyllabusSlider() {
  if ($(".home-cms-2-slider").length) {
    var syllabusBanner = new Swiper(".home-cms-2-slider", {
      slidesPerView: 3,
      autoplay: {
        delay: 5000
      },
      navigation: {
        nextEl: ".syllabus-button-next",
        prevEl: ".syllabus-button-prev"
      },
      breakpoints: {
        1199: {
          slidesPerView: 2
        },
        767: {
          slidesPerView: 1
        }
      }
    });
  }
}

function initInsta() {
  if (!window.INSTA_ID) {
    $("#insta-gallery").hide();
    return;
  }

  if ($("#insta-gallery").length) {
    $.ajax({
      type: "GET",
      url:
        "https://api.instagram.com/v1/users/self/media/recent/?access_token=" +
        window.INSTA_ID +
        "&count=5",
      crossDomain: true,
      dataType: "jsonp",
      success: function(response) {
        // console.log(response);

        response.data.forEach(function(insta) {
          var caption = "";
          var link = insta.link;
          var date = moment.unix(insta.created_time).format("DD MMMM YYYY");
          var image = insta.images.low_resolution.url;

          if (insta.caption) {
            if (insta.caption.hasOwnProperty("text")) {
              caption = insta.caption.text;

              var MAX_LENGTH = 100;
              var closestIndex = MAX_LENGTH;

              if (caption.length >= MAX_LENGTH) {
                caption = insta.caption.text.slice(0, MAX_LENGTH) + "...";
              }
            }
          }

          var ele =
            '<div class="col" style="background-image: url(' +
            image +
            ')"><a href="' +
            link +
            '" class="insta-gallery-feed-overlay" target="_blank"><span class="insta-gallery-feed-caption">' +
            caption +
            '</span><time class="insta-gallery-feed-time">' +
            date +
            "</time></a></div>";

          $(".insta-gallery-feed").append(ele);
        });
      },
      error: function(error) {
        // console.error(error);

        $("#insta-gallery").hide();
      }
    });
  }
}

function stickyHeader() {
  if ($(window).scrollTop() > $("#header").outerHeight()) {
    $("#header").addClass("sticky");
  } else {
    $("#header").removeClass("sticky");
  }
}

$(window).on("load", function() {
  setContentMinHeight();
});

function initMobileMenu() {
  var mmenu = mmlight($("#mobile-menu").get(0));

  mmenu.create("(max-width: 992px)");
  mmenu.init("current");

  $('a[href="#mobile-menu"]').on("click", function(ev) {
    mmenu.open();

    ev.preventDefault();
    ev.stopPropagation();
  });
}

function initAdsSlider() {
  if ($("#ads").length) {
    var adsSwiper = new Swiper("#ads .swiper-container", {
      autoplay: {
        delay: 5000
      }
    });
  }
}

function imgToBG(ele, _imgClass) {
  if (ele.length > 0) {
    ele.each(function() {
      var _this = $(this),
        img = _this.find(_imgClass).eq(0) || _this.find("img").eq(0);
      _this.css({
        "background-image": 'url("' + img.attr("src") + '")',
        "background-repeat": "no-repeat",
        "background-size": "cover"
      });
      img.hide();
    });
  }
}

function moveModalToBody(modal) {
  if ($(modal).length) {
    setTimeout(function() {
      $(modal).appendTo("body");
    }, 500);
  }
}

function isValidEmailAddress(emailAddress) {
  var pattern = new RegExp(
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
  );
  return pattern.test(emailAddress);
}

function setContentMinHeight() {
  var headerHeight = $("#header").outerHeight();
  var footerHeight = $("#footer").outerHeight();

  $("#content").css({
    "min-height": "calc(100vh - " + (headerHeight + footerHeight) + "px)",
    "margin-top": headerHeight + "px"
  });
}

function initStepModal() {
  var modals = $(".modal.multi-step");

  modals.each(function(idx, modal) {
    var $modal = $(modal);
    var $bodies = $modal.find("div.modal-body");
    var total_num_steps = $bodies.length;

    var $progress = $modal.find(".m-progress");
    var $progress_bar = $modal.find(".m-progress-bar");
    var $progress_stats = $modal.find(".m-progress-stats");
    var $progress_current = $modal.find(".m-progress-current");
    var $progress_total = $modal.find(".m-progress-total");
    var $progress_complete = $modal.find(".m-progress-complete");

    var reset_on_close = true;

    function reset() {
      $modal.find(".step").hide();
      $modal.find("[data-step]").hide();
      $modal.data("current", 1);
    }

    function completeSteps() {
      $progress_stats.hide();
      $progress_complete.show();

      $modal.find(".progress-text").animate({
        top: "-2em"
      });

      $modal.find(".complete-indicator").animate({
        top: "-2em"
      });

      $progress_bar.addClass("completed");
    }

    function getPercentComplete(current_step, total_steps) {
      return Math.min((current_step / total_steps) * 100, 100) + "%";
    }

    function updateProgress(current, total) {
      $progress_bar.animate({
        width: getPercentComplete(current, total)
      });

      if (current - 1 >= total_num_steps) {
        completeSteps();
      } else {
        $progress_current.text(current);
      }

      $progress.find("[data-progress]").each(function() {
        var dp = $(this);

        if (dp.data().progress <= current - 1) {
          dp.addClass("completed");
        } else {
          dp.removeClass("completed");
        }
      });
    }

    function goToStep(step) {
      reset();

      var to_show = $modal.find(".step-" + step);

      if (to_show.length === 0) {
        // at the last step, nothing else to show
        return;
      }

      $modal.data("current", step);

      to_show.show();

      // var current = parseInt(step, 10);
      // updateProgress(current, total_num_steps);

      findFirstFocusableInput(to_show).focus();
    }

    function findFirstFocusableInput(parent) {
      var candidates = [
        parent.find("input"),
        parent.find("select"),
        parent.find("textarea"),
        parent.find("button")
      ];

      var winner = parent;

      $.each(candidates, function() {
        if (this.length > 0) {
          winner = this[0];
          return false;
        }
      });

      return $(winner);
    }

    function bindEventsToModal($modal) {
      var data_steps = [];

      $("[data-step]").each(function() {
        var step = $(this).data().step;

        if (step && $.inArray(step, data_steps) === -1) {
          data_steps.push(step);
        }
      });

      $.each(data_steps, function(i, v) {
        $modal.on("next.m." + v, function(evt, data) {
          goToStep(data);
        });
      });
    }

    function initialize() {
      reset();

      // updateProgress(1, total_num_steps);

      $modal.find(".step-1").show();

      // $progress_complete.hide();
      // $progress_total.text(total_num_steps);

      bindEventsToModal($modal, total_num_steps);

      $modal.data({
        total_num_steps: $bodies.length
      });

      if (reset_on_close) {
        $modal.on("hidden.bs.modal", function() {
          reset();
          $modal.find(".step-1").show();
        });
      }
    }

    initialize();
  });
}

function initNewsletterListener() {
  $("#newsletter").submit(function(ev) {
    ev.preventDefault();

    var email = $("#newsletter .newsletter-field").val();

    if (!isValidEmailAddress(email)) {
      alert("Invalid email address.");
    } else {
      $("#newsletter-modal").modal("show");
    }
  });

  $("#newsletter-modal").on("show.bs.modal", function() {
    var email = $("#newsletter .newsletter-field").val();

    $("#newsletter_name").trigger("focus");
    $("#newsletter_email").val(email);
  });

  $("#newsletter-modal").on("hide.bs.modal", function() {
    $("#newsletter_name").val("");
    $("#newsletter_email").val("");

    grecaptcha.reset();
  });
}

function initTutorListener() {
  $("#tutor-modal").on("shown.bs.modal", function(ev) {
    var id = $(ev.relatedTarget).data("tutor-id");
    var url = $(this)
      .find("form")
      .attr("action");

    url = url.slice(0, -1) + id;

    $(this)
      .find("form")
      .attr("action", url);

    $(this)
      .find(".tutor-modal-info")
      .html($(".tutor-listing-info[data-tutor-id='" + id + "']").html());
  });

  $("#tutor-modal form input[name='character']").on("change", function(ev) {
    if ($(this).val() == "other") {
      $("#tutor-modal form input[name='other_character']").prop(
        "required",
        true
      );
    } else {
      $("#tutor-modal form input[name='other_character']").prop(
        "required",
        false
      );
    }
  });

  $("#tutor-modal form input[name='question']").on("change", function(ev) {
    var tutorForm = $("#tutor-modal form");

    if ($(this).val() == "other" && $(this).is(":checked")) {
      tutorForm.find("textarea[name='other_question']").prop("required", true);
    } else {
      tutorForm.find("textarea[name='other_question']").prop("required", false);
    }
  });

  $("#tutor-modal form").on("submit", function(ev) {
    var lastStep = 3;
    var current = $("#tutor-modal").data("current");

    if (current == lastStep) {
      if (
        !$(this)
          .find(".g-recaptcha")
          .val()
      ) {
        $(this)
          .find(".invalid-g-recaptcha")
          .show();
        ev.preventDefault();
      } else {
        $(this)
          .find("button[data-step='" + lastStep + "']")
          .prop("disabled", true);
        $(this)
          .find("button[data-step='" + lastStep + "']")
          .html('<i class="fas fa-spinner fa-lg fa-spin"></i>');

        return;
      }
    } else {
      ev.preventDefault();
    }

    var id = parseInt(current) + 1;

    $("#tutor-modal").trigger("next.m." + id, id);

    if (id == lastStep) {
      $(this)
        .find("input[name='question'][value='other']")
        .prop("required", true);
    } else {
      $(this)
        .find("input[name='question'][value='other']")
        .prop("required", false);
    }
  });
}

window.captchaCallback = function(response) {
  $(".g-recaptcha").val(response);
};
